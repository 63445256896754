import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGithub,
	faStackOverflow,
	faInstagram,
	faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Article from "../components/homepage/article";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";
import myArticles from "../data/articles";

import "./styles/homepage.css";
import axios from "axios";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(300);
	const [oldLogoSize, setOldLogoSize] = useState(300);
	const [articles, setArticles] = useState([])

	// useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);

	// useEffect(() => {
	// 	const handleScroll = () => {
	// 		let scroll = Math.round(window.pageYOffset, 2);

	// 		let newLogoSize = 300 - (scroll * 1) / 10;

	// 		if (newLogoSize < oldLogoSize) {
	// 			if (newLogoSize > 150) {
	// 				setLogoSize(newLogoSize);
	// 				setOldLogoSize(newLogoSize);
	// 				setStayLogo(false);
	// 			} else {
	// 				setStayLogo(true);
	// 			}
	// 		} else {
	// 			setLogoSize(newLogoSize);
	// 			setStayLogo(false);
	// 		}
	// 	};

	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => window.removeEventListener("scroll", handleScroll);
	// }, [logoSize, oldLogoSize]);

	useEffect(() => {
		const data = `query Publication {
			publication(host: "joymendonca.com"){
			  title
			  posts(first: 2){
				edges {
				  node {
					id
					title
					brief
					url
					subtitle
					publishedAt
				  }
				}
			  }
			}
		  }`
		axios({
			url: 'https://gql.hashnode.com',
			method: 'post',
			data: {query: data}
		}).then((result) => {
			if(result.data.data.publication.posts.edges){
				const articleData = result.data.data.publication.posts.edges;
				let articleArr = [];
				for(let i=0; i<articleData.length; i++){
					articleArr.push(articleData[i]['node'])
				}
				setArticles(articleArr);
			}
		});
	}, []);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div className="articles-logo">
							<Logo width={156} />
						</div>
					</div>
					<div className="homepage-logo-container desktop">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="homepage.jpg"
											alt="about"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.linkedin}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faLinkedin}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							{/* <a
								href={INFO.socials.stackoverflow}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faStackOverflow}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.instagram}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faInstagram}
									className="homepage-social-icon"
								/>
							</a> */}
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon"
								/>
							</a>
						</div>

						{/* <div className="homepage-projects">
							<AllProjects />
						</div> */}

						<div className="homepage-after-title">
							<div className="homepage-articles">
								{articles.map((article, index) => (
									<div
										className="homepage-article-item"
										key={(index + 1).toString()}
									>
										<Article
											key={(index + 1).toString()}
											date={new Date(article.publishedAt)}
											title={article.title}
											description={article.brief}
											link={"/article/" + article.id}
										/>
									</div>
								))}
							</div>

							<div className="homepage-works">
								<Works />
							</div>
						</div>

						<div className="page-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
